import { HeaderTheme, setHeaderDisplay, setHeaderTheme } from '@app-components'

{
    new IntersectionObserver(([element]) => {
        if (element.intersectionRatio >= 0.85) {
            setHeaderDisplay('none')
            setHeaderTheme(HeaderTheme.TRANSPARENT_WHITE)
        } else {
            setHeaderDisplay('brand-logo')
            setHeaderTheme(HeaderTheme.WHITE)
        }
    }, { threshold: [1, 0.85, 0.1, 0] })
}
